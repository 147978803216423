import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import useStyles from './componentStyles'


import CircleNum from './CircleNum'
import LandscapeIcon from '@material-ui/icons/Landscape';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
// import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';





const AppFeatureElaboration = () => {
    const classes = useStyles()
    return (
        <div style={{width: '100%', }}>
            
            <div  className={classes.appFeatureDesc}>
                <CircleNum  number={1}/>
                {/* <LandscapeIcon style={{fontSize: '60px'}}/> */}
                <Typography align={'center'}  style={{marginTop: 30}}>
                At Unindulgent, our top priority is nurturing a positive and respectful relationship between content creators and their subscribers. To support this, we have chosen not to offer direct messaging between individual subscribers and influencers.
                </Typography>
            </div>
            <div  className={classes.appFeatureDesc} style={{left: "52%"}}>
                <CircleNum  number={2}/>
                {/* <HourglassEmptyIcon style={{fontSize: '60px'}}/> */}
                <Typography align={'center'} style={{marginTop: 30}}>
                With each subscription, users will have the ability to access exclusive content from three content creators of their choice. Additionally, they'll be able to browse through shorter videos from all other content creators on our For You page
                </Typography>
            </div>
            <div  className={classes.appFeatureDesc}>
            <CircleNum  number={3}/>
                <Typography align={'center'} style={{marginTop: 30}}>
                At Unindulgent, we've pioneered a fresh approach for content creators to optimize their revenue streams by blending subscription-based and viewership-based payouts. With each new subscriber joining the platform, every content creator reaps the rewards—an advantage our competitors do not have!
                </Typography>
            </div>
            <div  className={classes.appFeatureDesc} style={{left: "52%"}}>
            <CircleNum  number={4}/>
                <Typography align={'center'} style={{marginTop: 30}}>
                At Unindulgent, we prioritize progress over ego. We welcome your advice, questions, concerns, or interest in joining our team. Don't hesitate to reach out, and we'll make sure to respond promptly.
                </Typography>
            </div>
        </div>
    )
}

export default AppFeatureElaboration