import { Typography, Button } from '@material-ui/core'
import {React, useState, useEffect} from 'react'
import CenteredTextFullScreen from '../../Components/CenteredTextFullScreen'
import LeftRightSplit from '../../Components/LeftRightSplit'

// import useStyles from '../../pages/pageStyles'
import useStyles from '../mobileStyles'

import senecaStatuePhoto from '../../images/SenecaStatuePhoto.jpg'
import MountainNight from '../../images/MountainNight.jpg'

import TextOnImage from '../../Components/TextOnImage'
import AppDemoDisplay from '../../Components/AppDemoDisplay'
import PurpleLine from '../../Components/PurpleLine'
import ContactUsButton from '../../Components/ContactUsButton'
import Footer from '../../Components/Footer'
import AppFeatureElaboration from '../../Components/AppFeatureElaboration'
import CircleNum from '../../Components/CircleNum'

const HEADER = "UNINDULGENT"
const HEADER_SECONDARY = "The app designed with you not in mind"

const MobileLandingPage = () => {
    const classes = useStyles()

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    {// God forbid anyone ever has to edit this code who is not me
         // I aimed to get this whole site done in less than a week so many many 
         // corners have been cut on all fronts

         // as a result, the file structure is horrendous, I haven't created proper mobile components
         // and margins are hardcoded in ways that no developer should ever do

         // hopefully this is truly just a placeholder and no one will have to maintain this abomonation
         // if you are reading this, that is clearly not the case... good luck.
    }
    return (
        
        <div className={classes.mobileLandingPage}>
            <div className={classes.CenterDiv}>
                <div style={{width: '100vw', height: '100vh', fontFamily: 'raleway', color: 'white'}}>
                    <img src={MountainNight} className={classes.backgroundImage} style={{zIndex: 0}}/>
                    <div className={`${classes.centerContainer} ${isVisible ? classes.fadeIn : ''}`} style={{position: 'relative', zIndex: 2}}>
                        <Typography variant='h3' align='center' style={{fontFamily: 'inherit',}}>
                            {HEADER}
                        </Typography>
                        <Typography variant='p1' align='center' style={{fontFamily: 'inherit', width: '100vw', justifyContent: 'center', display: 'flex'}}>
                            {HEADER_SECONDARY}
                        </Typography>
                    </div>
                    
                </div>
                <div  style={{width: '100%', height: '100vh'}}>
                    <img src={MountainNight} className={classes.backgroundImage} style={{zIndex: 0, top: '85vh', transform: "rotate(180deg)"}}/>
                    <Typography className={classes.centeredText} align="center" variant='p1' style={{fontFamily: 'raleway', lineHeight: 1.8, opacity: '80%', width: '100vw', textAlign: 'center', top: '120px'}}>
                        Our mission is to cultivate a thriving ecosystem where personal growth flourishes, 
                        free from intrusive ads and corporate sponsorships. We empower both creators and 
                        consumers by prioritizing community, fairness, and well-being, fostering loyalty and 
                        trust that benefits everyone in the long run. Together, we're redefining success in 
                        the creator economy, ensuring creators maximize their potential while users thrive in 
                        a supportive, ad-free environment.
                    </Typography>
                </div> 
                <div style={{width: '100%', position: 'relative', top: '-100px'}}>
                    <PurpleLine/>
                </div>
                <AppDemoDisplay/>
                <PurpleLine/>
                <div style={{width: '100%', }}>
                    <div  className={classes.appFeatureDesc}>
                        <CircleNum  number={1} size={"50px"}/>
                        {/* <LandscapeIcon style={{fontSize: '60px'}}/> */}
                        <Typography align={'center'}  style={{marginTop: 30}}>
                        At Unindulgent, our top priority is nurturing a positive and respectful relationship between content creators and their subscribers. To support this, we have chosen not to offer direct messaging between individual subscribers and influencers.
                        </Typography>
                    </div>
                    <div  className={classes.appFeatureDesc} style={{left: "52%"}}>
                        <CircleNum  number={2} size={"50px"}/>
                        {/* <HourglassEmptyIcon style={{fontSize: '60px'}}/> */}
                        <Typography align={'center'} style={{marginTop: 30}}>
                        With each subscription, users will have the ability to access exclusive content from three content creators of their choice. Additionally, they'll be able to browse through shorter videos from all other content creators on our For You page
                        </Typography>
                    </div>
                    <div  className={classes.appFeatureDesc}>
                        <CircleNum  number={3} size={"50px"}/>
                        <Typography align={'center'} style={{marginTop: 30}}>
                        At Unindulgent, we've pioneered a fresh approach for content creators to optimize their revenue streams by blending subscription-based and viewership-based payouts. With each new subscriber joining the platform, every content creator reaps the rewards—an advantage our competitors do not have!
                        </Typography>
                    </div>
                    <div  className={classes.appFeatureDesc} style={{left: "52%"}}>
                    <CircleNum  number={4} size={"50px"}/>
                        <Typography align={'center'} style={{marginTop: 30}}>
                        At Unindulgent, we prioritize progress over ego. We welcome your advice, questions, concerns, or interest in joining our team. Don't hesitate to reach out, and we'll make sure to respond promptly.
                        </Typography>
                    </div>
                </div>
                <div>

                </div>
                {/* <ContactUsButton/> */}
                <div style={{height: '250px', width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', paddingTop: '30px'}}>
                    <Typography variant='h5' style={{fontFamily: 'raleway', width: '60%', paddingBottom: 20}} align="center">
                        Please reach out to us with any questions or concerns
                    </Typography>
                    <Button variant='contained' style={{color: 'white', backgroundColor: '#361B71'}}
                    component="a"
                    href="mailto:Justin@unindulgent.com">
                        Contact Us
                    </Button>

                </div>
            </div>
        </div>
    )
}

export default MobileLandingPage

