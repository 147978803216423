import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({ // the theme object is part of MUI
    mobileLandingPage: {
        maxWidth: '100vh',
        backgroundColor: 'black',
        // width: '100vw',
        width: '100%',
        color: 'white',
        fontFamily: 'raleway',

    },
    CenterDiv: {
        maxWidth: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    centerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        textAlign: 'center',
        flexDirection: 'column',
        opacity: 0,
        transition: 'opacity 3s ease-in', // Adjust the transition duration and timing function as needed
    },
    fadeIn: {
        opacity: 1,
    },
    overlayContainer: {
        position: 'relative',
        height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        color: 'white',
    },
    backgroundImage: {
        opacity: 0.5,
        // filter: 'saturate(0.8)',
        height: '100vh',
        width: '100%',
        objectFit: 'cover',
        alignSelf: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        position: 'absolute',
        top: 0,
        left: 0,
    },
    centeredText: {
        position: 'relative',
        zIndex: 1,
        // widtH: '100%'
        display: 'flex'
    },
    appFeatureDesc: {
        // maxWidth: '33%',
        padding: 30
        
    }
    // ceneredContentDiv: {
    //     justifyContent: 'center',
    //     alignItems: 'ceneter',
    //     display: 'flex',
    //     flexDirection: 'column'
    // },
}));

export default useStyles;