import { Typography } from '@material-ui/core'
import {React, useEffect, useState} from 'react'
import useStyles from './componentStyles'
import PurpleLine from './PurpleLine'

import MountainNight from "../images/MountainNight.jpg"

const CenteredTextFullScreen = (props) => {
    const classes = useStyles()
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    return (
        <div>
            {/* <img src={MountainNight} style={{width: '100%',}}/> */}
        <div className={`${classes.centerContainer} ${isVisible ? classes.fadeIn : ''}`} style={{ position: 'relative', zIndex: 2}}>
            <Typography variant='h1' style={{fontFamily: "raleway"}}>
                {props.header}
            </Typography>
            <Typography variant='h5' style={{fontFamily: "raleway"}}>
                {props.secondary}
            </Typography>
        </div>
        </div>
    )
}

export default CenteredTextFullScreen