import { Typography } from '@material-ui/core'
import React from 'react'
import CenteredTextFullScreen from '../Components/CenteredTextFullScreen'
import LeftRightSplit from '../Components/LeftRightSplit'

import useStyles from './pageStyles'

import senecaStatuePhoto from '../images/SenecaStatuePhoto.jpg'
import MountainNight from '../images/MountainNight.jpg'

import TextOnImage from '../Components/TextOnImage'
import AppDemoDisplay from '../Components/AppDemoDisplay'
import PurpleLine from '../Components/PurpleLine'
import ContactUsButton from '../Components/ContactUsButton'
import Footer from '../Components/Footer'
import AppFeatureElaboration from '../Components/AppFeatureElaboration'

const HEADER = "UNINDULGENT"
const HEADER_SECONDARY = "The app designed with you not in mind"

const LandingPage = () => {
    const classes = useStyles()
    return (
        <div className={classes.LandingPage}>
            <div className={classes.CenterDiv}>
                {/* <TextOnImage
                    overlayElement={<CenteredTextFullScreen header={HEADER} secondary={HEADER_SECONDARY}/>}
                    image={MountainNight}
                />                 */}
                <div style={{width: '100vw', height: '100vh'}}>
                    <img src={MountainNight} className={classes.backgroundImage} style={{zIndex: 0}}/>
                    <CenteredTextFullScreen header={HEADER} secondary={HEADER_SECONDARY}/>
                </div>
                <div  style={{width: '100%', height: '100vh'}}>
                    <img src={MountainNight} className={classes.backgroundImage} style={{zIndex: 0, top: '90vh', transform: "rotate(180deg)"}}/>
                    <Typography className={classes.centeredText} align="center" variant='h4' style={{fontFamily: 'raleway', lineHeight: 1.8, paddingLeft: 70, paddingRight: 70, paddingTop: 250, opacity: '75%'}}>
                        Our mission is to cultivate a thriving ecosystem where personal growth flourishes, 
                        free from intrusive ads and corporate sponsorships. We empower both creators and 
                        consumers by prioritizing community, fairness, and well-being, fostering loyalty and 
                        trust that benefits everyone in the long run. Together, we're redefining success in 
                        the creator economy, ensuring creators maximize their potential while users thrive in 
                        a supportive, ad-free environment.
                    </Typography>
                </div> 
                <div style={{width: '100%', position: 'relative', top: '-98px'}}>
                    <PurpleLine/>
                </div>
                <AppDemoDisplay/>
                <PurpleLine/>
                <AppFeatureElaboration/>
                <ContactUsButton/>
                {/* <PurpleLine/> */}
                {/* <Footer/> */}
            </div>
            
        </div>
    )
}

export default LandingPage