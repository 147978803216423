import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({ // the theme object is part of MUI
    MobileNavbar: {
        display: 'flex',
        backgroundColor: 'white',
        color: 'black', // #F9E4BC <-- cream color?
        borderBottom: '2px solid black',
    },
    LandingPage: {
        backgroundColor: 'black',
        minHeight: '100vh',
        width: '100vw',
        color: "white"
    },
    TitleText: {
        fontFamily: "Raleway",
        fontSize: 80,
        paddingTop: 40
    },
    CenterDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    ParagraphText: {
        fontSize: 20
    },
    // ParagraphDiv: {
    //     flexDirection: 'row', 
    //     justifyContent: 'flex-start',
    //     display: 'flex',
    //     width: "100%",
    //     padding: 50,
    //     paddingLeft: 50
    // }
    responsiveImage: {
        width: '100%',
        // width: 'auto',
        height: '100%',
        height: 'auto',
        objectFit: 'cover',
        overflow: 'none'
    },
    backgroundImage: {
        opacity: 0.5,
        height: '100vh',
        width: '100%',
        objectFit: 'cover',
        alignSelf: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        position: 'absolute',
        top: 0,
        left: 0,
    },


}));

export default useStyles;