import { Button, Typography } from '@material-ui/core'
import React from 'react'

const ContactUsButton = () => {
    return (
        <div style={{height: '250px', width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
            <Typography variant='h4' style={{fontFamily: 'raleway', width: '60%', paddingBottom: 20}} align="center">
                Please reach out to us with any questions or concerns
            </Typography>
            <Button variant='contained' style={{color: 'white', backgroundColor: '#361B71'}}
            component="a"
            href="mailto:Justin@unindulgent.com">
                Contact Us
            </Button>

        </div>
    )
}

export default ContactUsButton