import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    circle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: '100%',
        width: '70px',
        height: '70px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        margin: '0 auto',
    },
    number: {
        fontSize: '2rem',
        color: 'black',
    }
}));

const CircleNum = ({ number, size }) => {
    const classes = useStyles();
    const newSize = size ? size : "70px"
    return (
        <div className={classes.circle} style={{width: newSize, height: newSize}}>
            <Typography className={classes.number}>
                {number}
            </Typography>
        </div>
    );
};

CircleNum.propTypes = {
    number: PropTypes.number.isRequired,
};

export default CircleNum;
