import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    line: {
        width: '100%',
        height: '20px',
        backgroundColor: '#361B71',
        opacity: '80%'
    },
}));

const PurpleLine = () => {
    const classes = useStyles();
    return (
        <div className={classes.line}></div>
    )
}

export default PurpleLine