import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import LandingPage from './Pages/LandingPage';
import MobileLandingPage from './mobile/mobilePages/MobileLandingPage';

const root = ReactDOM.createRoot(document.getElementById('root'));

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
root.render(
  <React.StrictMode>
    {/* <App /> */}
    <div>
      {
        isMobile ? <MobileLandingPage/> : <LandingPage/>
      }
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
