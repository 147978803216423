import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './componentStyles';

import appDemo1 from '../images/UnindulgentAppHomeDemo.png';
import appDemo2 from '../images/UnindulgentAppDemo2.png'
import appDemo3 from '../images/UnindulgentAppDemo3.png'
import appDemo4 from '../images/UnindulgentAppDemo4.png'

const demos = [appDemo1, appDemo2, appDemo3, appDemo4];

const AppDemoDisplay = () => {
    return (
        <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', paddingTop: 50}}>
            <Typography variant='h2' style={{fontFamily: "raleway",}}>
                Our Vision:
            </Typography>
            <Grid container spacing="5" style={{width: '100%', marginTop: '20px', padding: '30px', height: '100%', justifyContent: 'center', display: 'flex', alignItems: 'center', backgroundColor: 'black'}}>
                
                {demos.map((demo, index) => (
                    <Grid item key={index} xs='6' lg='3'>
                        <img src={demo} alt={`App demo ${index + 1}`} style={{width: "100%"}}/>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}

export default AppDemoDisplay;
